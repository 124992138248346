export class PathConstants {

    public static getUserDetailsPath(userId: any) {
        return `/provider-users/${userId}`;
    }

    public static getorganizationDetailsPath(orgCode: any) {
        return `/provider-org-${orgCode}/0rg-metadata`;
    }


    public static getScoreCardsPath(providerId: any, date: any) {
        return `/provider-orgs/${providerId}/insights/score-card/${date}/wi7hT80rh8d29dJo6tM4`;
    }
}