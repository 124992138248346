import { inject, Injectable } from '@angular/core';
import {
  Auth,
  authState,
  sendPasswordResetEmail,
  user
} from '@angular/fire/auth';
import {
  Database,
  get,
  ref
} from '@angular/fire/database';
import { signInWithEmailAndPassword } from '@firebase/auth';
import { BehaviorSubject, catchError, from, map, Observable, of, switchMap } from 'rxjs';
import { PathConstants } from '../db-paths';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private userSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private afAuth = inject(Auth);
  private authState$ = authState(this.afAuth);
  private db = inject(Database);

  constructor() {
    this.authState$.pipe(
      switchMap((user) => {
        if (!user) {
          return of(null);
        }

        // Reference to the user details in the Realtime Database
        const userRef = ref(this.db, PathConstants.getUserDetailsPath(user.uid));

        return get(userRef).then((snapshot) => {
          if (snapshot.exists()) {
            const userData = snapshot.val();
            console.log('[AuthService] User data from Realtime Database:', userData);

            // Check if the user has admin role
            if (userData.roles?.adm === true) {
              return userData;
            } else {
              console.warn('[AuthService] User does not have admin privileges.');
              return this.logout().then(() => null); // Log out and return null
            }
          }
          return null;
        }).catch((error) => {
          console.error('Error fetching user data from Realtime Database:', error);
          return this.logout().then(() => null); // Log out and return null
        });
      })
    ).subscribe((user) => {
      this.userSubject.next(user);
    });
  }

  getLoggedInUser(): Observable<any> {
    return this.userSubject.asObservable();
  }

  getOrgDetails(orgCode: string): Observable<any> {
    const orgDetailsPath = PathConstants.getorganizationDetailsPath(orgCode);
    console.log('[AuthService] Fetching organization details from path:', orgDetailsPath);

    const orgDetailsRef = ref(this.db, orgDetailsPath);

    return from(get(orgDetailsRef)).pipe(
      map((snapshot) => {
        if (snapshot.exists()) {
          console.log('[AuthService] Organization details fetched successfully:', snapshot.val());
          return snapshot.val();
        } else {
          console.warn('[AuthService] No organization details found for orgCode:', orgCode);
          return null;
        }
      }),
      catchError((error) => {
        console.error('Error fetching organization details from Realtime Database:', error);
        throw error;
      })
    );
  }

  isValidLogin(): Observable<boolean> {
    // Check if the logged-in user has admin privileges
    return this.userSubject.asObservable().pipe(
      map((user) => !!user && user.roles?.adm === true)
    );
  }

  login(email: string, password: string) {
    return signInWithEmailAndPassword(this.afAuth, email, password);
  }

  logout() {
    return this.afAuth.signOut().then(() => {
      this.userSubject.next(null);
    });
  }

  resetPassword(email: string) {
    return sendPasswordResetEmail(this.afAuth, email);
  }
}
